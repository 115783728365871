<template>
  <div></div>
</template>

<script>
export default {
  name: "TeamsConnection",

  beforeRouteEnter(to, from, next) {
    next(() => {
      const authCode = to.query.code;
      // console.log(authCode);

      if (authCode) {
        localStorage.setItem("teamsSignToken", authCode);
        window.close();
      }
    });
  },
};
</script>

<style></style>
